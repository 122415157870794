import {useState} from 'react';
import {IbanElement, useElements, useStripe} from '@stripe/react-stripe-js';

const Form = () => {
    const elements = useElements();
    const stripe = useStripe();
    const [billing, setBillngData] = useState({email: '', name: '', clinic_id: ''})
    const [msgStatus, setMsgStatus] = useState('Pendiente');

    let urlApi = '';
    if (process.env.NODE_ENV === 'development')
        urlApi = 'http://api.controldejornadalaboral.loc:8080/empresa/assign-iban';
    else
        urlApi = 'https://api.controldejornadalaboral.es/empresa/assign-iban';


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!billing.empresa_id && billing.empresa_id.trim().length == 0)
            throw new Error('No ha definido el empresa_id');

        const iban = elements.getElement(IbanElement);
        const payload=await stripe.createPaymentMethod({type: 'sepa_debit',sepa_debit:iban, billing_details: {name: billing.name, email: billing.email}});
        /*const payload = await stripe.createSource(iban, {
            type:'sepa_debit',
            currency: 'eur',
            owner: {name: billing.name, email: billing.email}
        })*/

        console.log('payload', payload);
        console.log('paymentMethod id', payload.paymentMethod);
        if (payload.error || payload.paymentMethod === null) {
            setMsgStatus('Error: ha habido algún problema. Intentalo otra vez')
        } else {


            const response = await fetch(urlApi +'?empresa_id='+ billing.empresa_id + '&payment_method_id=' + payload.paymentMethod?.id).then(res => res.json());

            if (response.created) {
                setMsgStatus("Ok");
            } else {
                setMsgStatus("Error: " + response);
            }
        }

    }

    const nameChanges = (event) => {
        console.log('nameChanges', event.target.id);
        billing[event.target.id] = event.target.value;
        setBillngData(billing);
    }

    return (
        <div className="m-3">
            {process.env.NODE_ENV==='development'  ? <div className="alert alert-danger">DESARROLLO</div> :''}
            <p className="h1 text-center">Alta SEPA</p>
            <div className="d-flex justify-content-center">
                <form className="form-group w-75" onSubmit={handleSubmit}>
                    <div className="alert alert-info">{msgStatus}</div>
                    <div>
                        <label>Empresa Id (id de stripe)</label>
                        <input className="form-control" id="empresa_id" type="text" onChange={nameChanges}/>
                    </div>

                    <div>
                        <label>Nombre </label>
                        <input className="form-control" id="name" type="text" onChange={nameChanges}/>
                    </div>

                    <div>
                        <label>Email </label>
                        <input className="form-control" id="email" type="text" onChange={nameChanges}/>
                    </div>

                    <IbanElement options={{
                        supportedCountries: ['SEPA']
                        , style: {
                            base: {
                                fontSize: '25px'
                            }
                        }
                    }}/>

                    <button className="btn btn-primary">Enviar</button>
                </form>
            </div>
        </div>
    )
}
export default Form;
