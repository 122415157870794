import Form from './Form';
import './App.css';
import {Elements} from '@stripe/react-stripe-js';

import {loadStripe} from '@stripe/stripe-js';

const pkStripe = (process.env.NODE_ENV === 'development') ?
    'pk_test_51ILOeaHDuQaJAlOmOXcXM8UwA6Xl1xqISvIY5vB5DaaKw96gW9haubzxRCeN0ol7PXwFj7jaTDa0N0TQo87S6EBD00mMSxy3Mc'
    : 'pk_live_51ILOeaHDuQaJAlOmYRfUfz7VQA4FxUYdBvQUwrfKmUllFlKMEuQhjp4ZyPChzSFa02Wtjh2amoxyu3C5gpL1bE2l00nUdfyf9M';

const stripePromise = loadStripe(pkStripe);

function App() {
    return (
        <Elements stripe={stripePromise}>
            <Form/>
        </Elements>
    )
}

export default App;
